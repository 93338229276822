import React, { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { NavLink, useMatch } from 'react-router-dom';
import {
  getListMenuArray,
  MenuGroupT,
  MenuSubgroupT,
} from '../../models/list-forms-model';
import { Icon, IconName } from '../uikit/Icon/Icon';
import { TextField } from '../uikit/TextField/TextField';

import styles from './Layout.module.css';

type MenuItemProps = {
  level?: number;
  isActive?: boolean;
  iconName?: IconName;
  isExtended: boolean;
  name: string;
  isOpenSubItem?: boolean;
  handleClick?: (flag: boolean) => void;
};

const MenuItem = ({
  level = 1,
  isActive = false,
  iconName,
  isExtended,
  name,
  isOpenSubItem,
  handleClick,
}: MenuItemProps) => {

  const elToScroll = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive) {
        elToScroll.current?.scrollIntoView({
          behavior: "auto",
          block: "nearest",
          inline: "nearest",
        });
    }
  }, [isActive]);

  return (
    <div
      ref={elToScroll}
      className={clsx(styles.menuItem, {
        [styles.menuActive]: isActive,
      })}
      onClick={() => handleClick?.(!isOpenSubItem)}
    >
      <div className={styles.menuItemIconWrap}>
        {iconName && <Icon name={iconName} />}
      </div>
      {isExtended && (
        <>
          <div style={{ marginLeft: 16 * (level - 1) }}>{name}</div>
          {handleClick && (
            <Icon
              name={isOpenSubItem ? 'ChevronDown' : 'ChevronRight'}
              size={16}
              className={styles.menuItemExpandIcon}
            />
          )}
        </>
      )}
    </div>
  );
};

type SubMenuGroupProps = { subMenuGroup: MenuSubgroupT; closeMenu: () => void };

const SubMenuGroup = ({ subMenuGroup, closeMenu }: SubMenuGroupProps) => {
  const { name, lists } = subMenuGroup;

  const [isOpenSubgroups, setIsOpenSubgroups] = useState(true);

  return (
    <>
      <MenuItem
        level={2}
        isExtended
        name={name}
        isOpenSubItem={isOpenSubgroups}
        handleClick={setIsOpenSubgroups}
      />
      {isOpenSubgroups &&
        lists.map((item) => (
          <NavLink key={item.path} to={item.path} onClick={() => closeMenu()}>
            {({ isActive }) => (
              <MenuItem
                level={3}
                isActive={isActive}
                isExtended
                name={item.name}
              />
            )}
          </NavLink>
        ))}
    </>
  );
};

type MenuGroupProps = {
  menuGroup: MenuGroupT;
  isExtended: boolean;
  extendMenu: () => void;
  closeMenu: () => void;
};

const MenuGroup = ({
  menuGroup,
  isExtended,
  extendMenu,
  closeMenu,
}: MenuGroupProps) => {
  const { name, iconName, path, subgroups, lists } = menuGroup;

  const [isOpenSubgroups, setIsOpenSubgroups] = useState(true);

  const match = useMatch(`${path}/*`);

  if (subgroups && subgroups.length > 0) {
    return (
      <>
        <MenuItem
          iconName={iconName}
          isExtended={isExtended}
          // подсвечиваем активной группу если она или всё меню свернуты
          isActive={(!isExtended || !isOpenSubgroups) && Boolean(match)}
          name={name}
          isOpenSubItem={isOpenSubgroups}
          handleClick={isExtended ? setIsOpenSubgroups : extendMenu}
        />
        {isExtended &&
          isOpenSubgroups &&
          subgroups.map((subgroup) => (
            <SubMenuGroup
              key={subgroup.code}
              subMenuGroup={subgroup}
              closeMenu={closeMenu}
            />
          ))}
      </>
    );
  }

  if (lists && lists.length > 0) {
    return (
      <>
        <MenuItem
          iconName={iconName}
          level={1}
          isExtended={isExtended}
          // подсвечиваем активной группу если она или всё меню свернуты
          isActive={(!isExtended || !isOpenSubgroups) && Boolean(match)}
          name={name}
          isOpenSubItem={isOpenSubgroups}
          handleClick={isExtended ? setIsOpenSubgroups : extendMenu}
        />
        {isExtended && isOpenSubgroups &&
          lists.map((item) => (
            <NavLink key={item.path} to={item.path} onClick={() => closeMenu()}>
              {({ isActive }) => (
                <MenuItem
                  level={3}
                  isActive={isActive}
                  isExtended
                  name={item.name}
                />
              )}
            </NavLink>
          ))}
      </>
    );
  }

  return (
    <NavLink to={path}>
      {({ isActive }) => (
        <MenuItem
          isActive={isActive}
          iconName={iconName}
          isExtended={isExtended}
          name={name}
        />
      )}
    </NavLink>
  );
};

export const NavLayout = () => {
  const [isExtended, setIsExtended] = useState(false);
  const [filter, setFilter] = useState('');

  const handleToggleExtended = () => setIsExtended((flag) => !flag);

  const menuList: MenuGroupT[] = [
    {
      code: 'main',
      path: '/',
      iconName: 'Home',
      name: 'Главная',
      subgroups: [],
    },
    ...getListMenuArray(filter),
  ];

  return (
    <>
      {isExtended && (
        <div
          className={styles.appMenuBackdrop}
          onClick={handleToggleExtended}
        />
      )}
      <aside
        className={clsx(styles.appMenu, { [styles.menuExtended]: isExtended })}
      >
        <div className={styles.menuButton} onClick={handleToggleExtended}>
          <Icon name={isExtended ? 'Close' : 'Hamburger'} fill="white" />
        </div>

        <div
          className={styles.menuWrap}
          style={{
            backgroundColor: isExtended
              ? 'var(--color-menu-expanded)'
              : 'var(--color-menu-folded)',
          }}
        >
          <div className={styles.menuItems}>
            {isExtended && (
              <div
                style={{
                  padding: '16px 16px 16px 16px',
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'var(--color-menu-expanded)',
                  zIndex: 1,
                }}
              >
                <TextField
                  value={filter}
                  handleChange={setFilter}
                  clearable
                  endElements={[
                    <Icon name="Search" style={{ marginRight: 8 }} />,
                  ]}
                ></TextField>
              </div>
            )}
            <nav>
              {menuList.map((menuGroup) => (
                <MenuGroup
                  key={menuGroup.code}
                  menuGroup={menuGroup}
                  isExtended={isExtended}
                  extendMenu={handleToggleExtended}
                  closeMenu={() => setIsExtended(false)}
                />
              ))}
            </nav>
          </div>

          {/* For DEV */}
          <div
            style={{
              marginBottom: 12,
            }}
          >
            <NavLink to="/icons">
              {({ isActive }) => (
                <div
                  className={clsx(styles.menuItem, {
                    [styles.menuActive]: isActive,
                  })}
                >
                  <div className={styles.menuItemIconWrap}>Icons</div>
                </div>
              )}
            </NavLink>
            <NavLink to="/uikit">
              {({ isActive }) => (
                <div
                  className={clsx(styles.menuItem, {
                    [styles.menuActive]: isActive,
                  })}
                >
                  <div className={styles.menuItemIconWrap}>UI</div>
                </div>
              )}
            </NavLink>
          </div>
        </div>
      </aside>
    </>
  );
};
