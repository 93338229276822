import React, { useContext, useEffect, useState } from 'react';
import { produce } from 'immer';
import { MetadataContext } from '../../../propviders/MetadataPropvider';
import {
  FieldValueObject,
  FormDataItem,
  ListFormData,
} from '../../../types/common/form';
import { ButtonIcon } from '../../uikit/ButtonIcon/ButtonIcon';
import { ModalListViewEditRecordController } from '../CardFormController/ModalListViewEditRecordController';
import { FormListView } from '../CardForms/CardFormSimple';
import { CardFormContext } from '../contexts/CardFormContext';
import { getSchema, ServiceFields } from '../helper';
import { ListFormTable } from '../ListFormTable/ListFormTable';

const listFormHeaderHeight = 56;
const listFormHeaderBottomMargin = 10;
const controlPanelHeight = 40;


type Props = {
  listView: FormListView;
};

export const ListFormListViewController = ({ listView }: Props) => {
  const { isLoadingMetadata, metaFields } = useContext(MetadataContext);
  const {
    form: parentForm,
    id: parentId,
    setFieldValueListViews,
    isEdited,
  } = useContext(CardFormContext);

  const listViewCode = listView.code;

  const [formData, setFormData] = useState<ListFormData>({
    rows: [],
    fields: [],
  });

  const [recordToEdit, setRecordToEdit] = useState<FormDataItem | null>(null);
  const [isOpenedAddingRecord, setIsOpenedAddingRecord] = useState(false);

  useEffect(() => {
    const fields = getSchema(listView.values, metaFields).filter(
      (field) => !ServiceFields.includes(field.code)
    );
    // const rows = transformData(listView.values, fields);
    setFormData({
      rows: listView.values.map((row, index) => ({
        ...row,
        _key: index,
        _selected: false,
      })),
      fields,
    });
  }, [listView.values, metaFields]);

  // const { formData, setFormData, isLoading, fetchDict } = useFormSchemaAndData({ form });

  const selectedRows = formData.rows
    .map((row, index) => ({ ...row, _index: index }))
    .filter(({ _selected }) => _selected);

  const selectRow = (key: number, value: boolean) => {
    setFormData((_form) =>
      produce(_form, (draft) => {
        const rowIndex = draft.rows.findIndex(({ _key }) => _key === key);
        draft.rows[rowIndex]._selected = value;
      })
    );
  };

  const handleSubmitEditRow = (
    editedListViewRecord: FormDataItem,
    isNew: boolean
  ) => {
    const idEditedListViewRecord = formData.rows.find(
      (row) => row._key === editedListViewRecord._key
    )?.id;

    // Получаем преобразованные данные из editedListViewRecord
    const filteredRecord: FieldValueObject = Object.keys(editedListViewRecord)
      .filter((key) => metaFields[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: editedListViewRecord[key],
        }),
        {}
      );

    const newListViewValues: FieldValueObject[] = isNew
      ? [...listView.values, filteredRecord]
      : listView.values.map((record) =>
          record.id === idEditedListViewRecord
            ? { ...record, ...filteredRecord }
            : record
        );

    if (parentForm) {
      setFieldValueListViews(listViewCode, newListViewValues);
    }
  };

  const handleDeleteRecords = () => {
    if (selectedRows.length > 0) {
      const newRecords = listView.values.filter(
        (_, index) =>
          !selectedRows.map(({ _index }) => _index).includes(index)
      );
      setFieldValueListViews(
        listViewCode,
        newRecords,
      );
    }
  };

  // Добавил проверку на всякий случай. Рассчитываю, что это условие не случится, т.е. мы на данный момент не попадаем сюда если не загружена метадата
  if (isLoadingMetadata) return <div>Загрузка метадаты...</div>;

  return (
    <div
      style={{
        width: '100%',
        height: `calc(100% - ${listFormHeaderHeight + listFormHeaderBottomMargin}px)`,
        overflow: 'auto',
      }}
    >
      {isEdited && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            height: controlPanelHeight,
          }}
        >
          <ButtonIcon iconName="Edit" disabled={selectedRows.length !== 1} onClick={() => {
            const recordToEdit = formData.rows.find(({_key}) => _key === selectedRows[0]._key);
            recordToEdit && setRecordToEdit(recordToEdit);
          }}/>
          <ButtonIcon iconName="Minus" onClick={handleDeleteRecords} disabled={selectedRows.length === 0}/>
          <ButtonIcon
            iconName="Plus"
            onClick={() => {
              setIsOpenedAddingRecord(true);
            }}
          />
        </div>
      )}
      <ListFormTable
        listFormData={formData}
        selectRow={isEdited ? selectRow : undefined}
        // setFieldProp={setFieldProp}
        // setSortField={setSortField}
        handleDoubleRecordClick={
          isEdited
            ? (id: string, record: FormDataItem) => {
                record && setRecordToEdit(record);
              }
            : undefined
        }
      />
      {recordToEdit && (
        <ModalListViewEditRecordController
          listViewCode={listViewCode}
          record={recordToEdit}
          handleSubmit={(record: FormDataItem) => {
            handleSubmitEditRow(record, false);
            setRecordToEdit(null);
          }}
          handleCancel={() => {
            setRecordToEdit(null);
          }}
        />
      )}
      {isOpenedAddingRecord && (
        <ModalListViewEditRecordController
          listViewCode={listViewCode}
          handleSubmit={(record: FormDataItem) => {
            handleSubmitEditRow(record, true);
            setIsOpenedAddingRecord(false);
          }}
          handleCancel={() => {
            setIsOpenedAddingRecord(false);
          }}
        />
      )}
    </div>
  );
};
