import React from 'react';
import { Checkbox } from '../../uikit/Checkbox/Checkbox';
import { FormField } from '../CardForms/CardFormSimple';


type Props = {
  field: FormField;
};

export const FieldReadonly = ({field }: Props) => {
  const getValueText = () => {
    switch (field.type) {
      case 'Boolean': {
        return (
          <Checkbox
            checked={field.value === 'true'}
          />
        );
      }
      case 'Enum':
      case 'Dictionary': {
        const valueToCompare = (
          typeof field.value === 'object' &&
          field.value !== null &&
          'id' in field.value
        )
          ? field.value.id
          : field.value;

        const valueText = field.options?.find(
          option => option.value === String(valueToCompare)
        )?.name;
        return valueText;
      }
      default:
        return field.value;
    }
  };

  return <>{getValueText()}</>
};
