import { MetaColumnFieldTypes, MetaFields } from '../../propviders/MetadataPropvider';
import {
  FieldType, FieldValueObject, FormDataItem,
  FormMetadata, ListFormDataResponse,
  SchemaField,
} from '../../types/common/form';


// Я исключил от сюда поле id потому, что когда мы смотрим ListForm справочника,
// который присылается и отображается как есть с бека и строится на вьюхе, там есть id,
// а значит его нужно отображать там, и как следствие наверное и тут.
// (но при этом в справочнике adm/metadata этого поля нет, странно...)
export const ServiceFields = [
  'createBy',
  'created',
  'changeBy',
  'changed',
  'externalId',
  'ldelete',
  'isNew',
];
export const ReadOnlyFields = [
  'fullName',
];

export const getSchema = (data: FormDataItem[] | FieldValueObject[], metaFields: MetaFields): SchemaField[] => {
  if (data.length === 0) return [];

  // Получаем первый объект для анализа структуры
  const firstItem = data[0];
  const schema: SchemaField[] = [];

  Object.keys(firstItem).forEach((key) => {
    if (MetaColumnFieldTypes.includes(metaFields[key]?.type || 'String')) {
      schema.push({
        code: key,
        type: metaFields[key]?.type as FieldType || 'String',
        filter: [],
        sort: null,
        pinned: null,
        width: null,
      });
    }
  });

  return schema;
};

export const getSchemaCard = (data: ListFormDataResponse, metaFields: MetaFields): SchemaField[] => {

  let metadataFields: { [p: string]: FieldType } = {};

  const firstRow = data.data?.[0];

  // если есть данные, то сортируем мету по порядку полей как в данных.
  if (firstRow) {
    Object.keys(firstRow).forEach(key => {
      if (key in data.metadata.fields) {
        metadataFields[key] = data.metadata.fields[key];
      }
    });
    // Добавляем поля из metadata.fields, которых нет в firstRow
    Object.keys(data.metadata.fields).forEach(key => {
      if (!(key in metadataFields)) {
        metadataFields[key] = data.metadata.fields[key];
      }
    });
  } else {
    metadataFields = data.metadata.fields;
  }


  const schema: SchemaField[] = [];

  Object.keys(metadataFields).forEach((key) => {
    if (MetaColumnFieldTypes.includes(metaFields[key]?.type) && !ServiceFields.includes(key)) {
      schema.push({
        code: key,
        type: metaFields[key]?.type as FieldType || 'String',
        filter: [],
        sort: null,
        pinned: null,
        width: null,
      });
    }
  });

  return schema;
};

export const validateStorage = (
  fields: SchemaField[],
  storageFields: SchemaField[] | null
): boolean => {
  if (storageFields === null || fields.length !== storageFields.length) {
    return false;
  }

  for (let i = 0; i < fields.length; i++) {
    if (
      !storageFields.find(
        (sField) =>
          sField.code === fields?.[i].code && sField.type === fields?.[i].type
      )
    ) {
      return false;
    }
  }

  return true;
};
