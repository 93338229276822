import React from 'react';
import { IForm } from '../../../models/list-forms-model';
import { FieldValueObject } from '../../../types/common/form';

type ICardFormContext = {
  fetchCard(): void;
  form?: IForm,
  id?: number | undefined,
  setFieldValueListViews: (listViewCode: string, newRecords: FieldValueObject[]) => void;
  isEdited?: boolean;
};

export const CardFormContext = React.createContext<ICardFormContext>({
  fetchCard: () => {},
  setFieldValueListViews: () => {},
});
