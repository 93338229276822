import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../Button/Button';
import { ButtonIcon } from '../ButtonIcon/ButtonIcon';
import { DatePicker } from '../DatePicker/DatePicker';
import { Dropdown } from '../Dropdown/Dropdown';
import { Icon } from '../Icon/Icon';
import { OptionValue } from '../SelectField/SelectField';
import { TextField, TextFieldProps } from '../TextField/TextField';



export const DAYJS_FORMAT_VALUE_MASK = 'DD.MM.YYYY H:mm:ss';

type DateFieldProps = Omit<TextFieldProps, 'value' | 'handleChange'> & {
  label?: string;
  value: Date | null;
  handleChange: (date: Date | null) => void;
};

export function DateField({
  label,
  value,
  handleChange,
  ...otherInputProps
}: DateFieldProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const valuesText = value ? dayjs(value).format(DAYJS_FORMAT_VALUE_MASK) : '';
  const [inputText, setInputText] = useState(valuesText);

  useEffect(() => {
    setInputText(valuesText);
  }, [valuesText]);

  const inputRef = useRef<HTMLInputElement>(null);

  const openDropdown = () => {
    setIsDropdownOpen(true);
  };

  const handleButtonClick = () => {
    if (isDropdownOpen) {
      closeDropdown();
    } else {
      openDropdown();
    }
  };

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = () => {
    openDropdown();
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleClose = () => {
    closeDropdown();
  };

  // const handleChangeItem = (value: OptionValue) => {
  //     handleChange([value]);
  //     handleClose();
  // };



  return (
    <Dropdown
      open={isDropdownOpen}
      onClose={handleClose}
      width={300}
      dropdown={
        <div
          style={{
            backgroundColor: 'white',
            border: '1px rgba(224, 230, 237, 1) solid',
            height: 292,
          }}
        >
          <DatePicker date={value} handleChange={handleChange}/>
        </div>
      }
    >
      <TextField
        inputRef={inputRef}
        value={inputText}
        handleChange={setInputText}
        readOnly={isDropdownOpen}
        // onFocus={handleFocus}
        endElements={[
          // <Button
          //   onClick={() => handleButtonClick()}
          //   variant="text"
          //   iconEnd={
          //     <Icon name={isDropdownOpen ? 'ChevronUp' : 'ChevronDown'} />
          //   }
          //   overrideFillIcon
          //   style={{
          //     backgroundColor: isDropdownOpen ? '#D3DCE6' : undefined,
          //     padding: 6,
          //     marginRight: 1,
          //   }}
          // />,
          <ButtonIcon iconName="Calendar" onClick={handleButtonClick} rounded />
        ]}
      />
    </Dropdown>
  );
}
