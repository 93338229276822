import React, { createContext, useEffect, useState } from 'react';
import {
  fetchMetadata,
  IMetadataItem,
  MetadataItemType,
} from '../servises/adm';
import { transformArrayToObject, uniqBy } from '../utils/functions';

export const MetaColumnFieldTypes: MetadataItemType[] = [
  'String',
  'Number',
  'Boolean',
  'DateTime',
  'Enum',
  'Dictionary',
  'ListView',
];

export type MetaEnums = Record<
  string,
  { dict: string; values: Record<string, IMetadataItem>, valuesArray: IMetadataItem[] }
>;
export type MetaFields = Record<string, IMetadataItem>;

interface MetadataContextValue {
  metaEnums: MetaEnums;
  metaDictionary: IMetadataItem[];
  metaListViews: IMetadataItem[];
  metaFields: MetaFields;
  isLoadingMetadata: boolean;
}

export const MetadataContext = createContext<MetadataContextValue>({
  metaEnums: {},
  metaDictionary: [],
  metaListViews: [],
  metaFields: {},
  isLoadingMetadata: false,
});
MetadataContext.displayName = 'MetadataContext';

type MetaDataProviderProps = { children: React.ReactNode };

export const MetaDataProvider = ({ children }: MetaDataProviderProps) => {
  const [metadata, setMetadata] = useState<IMetadataItem[]>([]);
  const [isLoadingMetadata, setIsLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const fetch = async () => {
      setIsLoading(true);
      fetchMetadata(signal)
        .then((data) => {
          setMetadata(data || []);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetch().then();

    return () => {
      abortController?.abort();
    };
  }, []);

  const metaEnums = transformArrayToObject(
    'dict',
    uniqBy(
      metadata.filter((d) => d.type === 'Enum'),
      (i) => i.dict
    ).map((enumDict) => {
      const enumValues = metadata.filter((d) => d.type === '' && d.dict === enumDict.dict);
      const values = transformArrayToObject(
        'objectId',
        metadata.filter((d) => d.type === '' && d.dict === enumDict.dict)
      );
      return { dict: enumDict.dict, values, valuesArray: enumValues };
    })
  );
  const metaDictionary = metadata.filter((d) => d.type === 'Dictionary');
  const metaListViews = metadata.filter((d) => d.type === 'ListView');
  const metaFields = transformArrayToObject(
    'objectId',
    metadata.filter((d) => MetaColumnFieldTypes.includes(d.type))
  );

  const contextValue = {
    metaEnums,
    metaDictionary,
    metaListViews,
    metaFields,
    isLoadingMetadata,
  };

  return (
    <MetadataContext.Provider value={contextValue}>
      {children}
    </MetadataContext.Provider>
  );
};
