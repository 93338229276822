import { clsx } from 'clsx';
import React from 'react';
import { IForm } from '../../../models/list-forms-model';
import {
  FieldType,
  FieldValue, FieldValueObject,
} from '../../../types/common/form';
import { ParentSize } from '../../../utils/resize-observer/adapters/ParentSize';
import { Option } from '../../uikit/SelectField/SelectField';
import { FieldEdit } from '../FormFields/FieldEdit';
import { FieldReadonly } from '../FormFields/FieldReadonly';
import { ListFormListViewTabs } from '../ListFormController/ListFormListViewTabs';
import styles from './CardForm.module.css';

export interface FormField {
  code: string;
  label: string;
  type: FieldType;
  oldValue: FieldValue;
  value: FieldValue;
  options?: Option[];
}
export interface FormListView {
  code: string;
  label: string; // возможно это поле лишнее тут, так как мы можем получить лейбл из metadata, получается повторение данных...
  values: FieldValueObject[];
}

type CardFormSimpleProps = {
  formName?: IForm['name'];
  fields: FormField[];
  setFieldValue: (fieldCode: string, value: string | null) => void;
  isReadOnly?: boolean;
  listViews?: FormListView[];
};

export const CardFormSimple = ({
  formName,
  fields,
  setFieldValue,
  isReadOnly = false,
  listViews,
}: CardFormSimpleProps) => {

  return (
    <div className={styles.CardForm}>
      {formName && <div className={styles.Header}>{formName}</div>}
      <div className={styles.Form}>
        <div className={styles.FormWrap}>
          <div className={styles.ContainerWrap}>
            <div className={styles.Container}>
              {fields.map((field) => (
                <div key={field.code} className={styles.FieldContainer}>
                  <div className={styles.LabelWrap}>
                    <div className={styles.Text2Lines}>{field.label}:</div>
                  </div>
                  <div className={styles.InputWrap}>
                    {isReadOnly ? (
                      <div className={clsx(styles.Value, styles.Text2Lines)}>
                        <FieldReadonly field={field} />
                      </div>
                    ) : (
                      <FieldEdit field={field} setFieldValue={setFieldValue} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {listViews && listViews.length > -1 && (
            <div style={{ minHeight: 300 }}>
              <ParentSize>
                {({ height }) => {
                  return (
                    <div style={{ height }}>
                      {/*<ListFormController form={form2!} />*/}
                      <ListFormListViewTabs listViews={listViews} />
                    </div>
                  );
                }}
              </ParentSize>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
