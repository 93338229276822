import dayjs from 'dayjs';
import React from 'react';
import { FormsList } from '../../../models/list-forms-model';
import { ParameterFuncType, UrlParameter } from '../../../utils/urlTemplate';
import { DateField } from '../../uikit/DateFIeld/DateField';
import { NumberField } from '../../uikit/NumberField/NumberField';
import { Option, SelectField } from '../../uikit/SelectField/SelectField';
import { TextField } from '../../uikit/TextField/TextField';
import { useFormSchemaAndData } from '../hooks';

import styles from './UserFiltersFormController.module.css'

type DictionaryInputProps = {
  dictCode: string;
  value: string;
  onChange: (value: string) => void;
};

const DictionaryInput = ({
  dictCode,
  value,
  onChange,
}: DictionaryInputProps) => {
  const form = FormsList.find((form) => form.code === dictCode) || FormsList[0];
  const { formData } = useFormSchemaAndData({ form });

  if (!dictCode || !form) return <>Некорректный шаблон</>;

  const options: Option[] = formData.rows.map((row) => ({
    value: String(row.id),
    name: String(row.fullName),
  }));

  return (
    <SelectField
      values={[value]}
      options={options}
      handleChange={(values) => {
        onChange(values[0]);
      }}
    />
  );
};

const FieldWrapper = ({
  label,
  width = 300,
  children,
}: {
  label: string;
  width?: number;
  children: React.ReactNode;
}) => (
  <div className={styles.FieldWrap}>
    <label>{label}</label>
    <div style={{ width }}>{children}</div>
  </div>
);

const renderFunctions: Record<
  ParameterFuncType,
  (
    args: string[],
    value: string,
    onChange: (newValue: string) => void
  ) => JSX.Element
> = {
  string: (args, value, onChange) => {
    const label = args[0];
    if (!label) return <>Некорректный шаблон</>;
    return (
      <FieldWrapper label={label}>
        <TextField value={value} handleChange={onChange} />
      </FieldWrapper>
    );
  },
  number: (args, value, onChange) => {
    const label = args[0];
    if (!label) return <>Некорректный шаблон</>;
    return (
      <FieldWrapper label={label}>
        <NumberField
          value={Number(value)}
          handleChange={(number) => onChange(String(number))}
        />
      </FieldWrapper>
    );
  },
  date: (args, value, onChange) => {
    const label = args[0];
    const format = args[1];
    if (!label || !format) return <>Некорректный шаблон</>;

    const date = value ? dayjs(value, format).toDate() : null;
    const handleChange = (date: Date | null) =>
      onChange(dayjs(date).format(format));

    return (
      <FieldWrapper label={label}>
        <DateField label={label} value={date} handleChange={handleChange} />
      </FieldWrapper>
    );
  },
  dict: (args, value, onChange) => {
    const dictCode = args[0];
    const form = FormsList.find((form) => form.code === dictCode);
    if (!dictCode || !form) return <>Некорректный справочник</>;

    return (
      <FieldWrapper label={form.name}>
        <DictionaryInput
          dictCode={dictCode}
          value={value}
          onChange={onChange}
        />
      </FieldWrapper>
    );
  },
};

// Основная функция для рендера компонента по параметру
export const renderUserFilterInput = (
  parameter: UrlParameter,
  onChange: (newValue: string) => void
): JSX.Element => {
  const { type, argument, value } = parameter;
  const renderFunction = renderFunctions[type];

  if (!renderFunction) {
    throw new Error(`Unsupported type: ${type}`);
  }

  return renderFunction(argument, value, onChange);
};
