import { IconName } from '../components/uikit/Icon/Icon';


export type ServiceCode = 'nsi' | 'dsp' | 'pln' | 'adm';

// prettier-ignore
export const Groups = {
  'Основная НСИ': { path: 'main-nsi', iconName: 'Note' },
  'Технологическая НСИ': { path: 'technological-nsi', iconName: 'List' },
  'Планирование': { path: 'planning', iconName: 'CalendarClock' },
  'Диспетчеризация': { path: 'dispatching', iconName: 'Draft' },
  'Логистика': { path: 'logistics', iconName: 'ArrowRight' },
  'Закупки': { path: 'procurement', iconName: 'Draft' },
  'Регистры': { path: 'registers', iconName: 'Draft' },
  'Отчеты': { path: 'reports', iconName: 'Draft' },
  'Администратор': { path: 'administrator', iconName: 'Settings' },
} as const;

export type GroupCode = keyof typeof Groups;

export interface IFormGroup {
  code: GroupCode;
  path: string;
  iconName: IconName;
}

export const GroupsList: IFormGroup[] = (
  Object.entries(Groups) as [GroupCode, (typeof Groups)[GroupCode]][]
).map(([code, {  path, iconName }]) => ({ code, path, iconName }));

// prettier-ignore
export const SubGroups = {
  'Взаимоотношения' : { path: 'relationships' },
  'Виды работ': { path: 'types-of-work' },
  'Номенклатура': { path: 'nomenclature' },
  'Оборудование': { path: 'equipment' },
  'Персонал': { path: 'personnel' },
  'Структура производства': { path: 'production-structure' },
  'Другие справочники': { path: 'other-reference-books' },
  'Технологические составы': { path: 'technological-compositions' },
  'Техпроцессы': { path: 'technological-processes' },
  'Заказы': { path: 'orders' },
  'Планы': { path: 'plans' },
  'Настройки': { path: 'settings' },
  // 'План цеха': { path: 'workshop-plan' }, нет 2 уровня, пока не заполнены
  // 'План обеспечения': { path: 'provision-plan' },
  // 'Комплектация сборки': { path: 'assembly-kit' },
  // 'Технологические паспорта': { path: 'technological-passports' },
  // 'Карты доработок': { path: 'improvement-maps' },
  'Запасы': { path: 'stocks' },
  'Партии': { path: 'parties' },
  'Логистика': { path: 'logistics' },
  // 'Инвентаризация': { path: 'inventory' }, пока не заполнены
  // 'Настройки': { path: 'settings' }, уже есть
  // 'Обеспечение': { path: 'providing' }, пока не заполнены
  // 'ТМЦ': { path: 'tmc' }, нет 2 уровня
  // 'НЗП': { path: 'nzp' },
  // 'ТМЦ итог': { path: 'tmc-total' },
  // 'НЗП итог': { path: 'nzp-total' },
  // 'Стандартные': { path: 'standard' },
  // 'Сводные': { path: 'pivot' },
} as const;

export type SubGroupCode = keyof typeof SubGroups;

export interface IFormSubGroup {
  code: SubGroupCode;
  path: string;
}

export const SubGroupsList: IFormSubGroup[] = (
  Object.entries(SubGroups) as [
    SubGroupCode,
    (typeof SubGroups)[SubGroupCode],
  ][]
).map(([code, { path }]) => ({ code, path }));

export interface Form {
  api?: string; // если нет берем code, заполнен там где указан urlParams
  urlParams?: string;
  name: string;
  groupCode: GroupCode;
  subGroupCode?: SubGroupCode;
  serviceCode: ServiceCode;
  isOnlyRead?: boolean;
}

// prettier-ignore
export const Forms: Record<string, Form> = {
  // 1 ур. Основная НСИ
  // Взаимоотношения
  'currency': { name: 'Валюты', groupCode: 'Основная НСИ', subGroupCode: 'Взаимоотношения', serviceCode: 'nsi' },
  'counterparty_group': { name: 'Группы контрагентов', groupCode: 'Основная НСИ', subGroupCode: 'Взаимоотношения', serviceCode: 'nsi' },
  'contract': { name: 'Договоры', groupCode: 'Основная НСИ', subGroupCode: 'Взаимоотношения', serviceCode: 'nsi' },
  'counterparty': { name: 'Контрагенты', groupCode: 'Основная НСИ', subGroupCode: 'Взаимоотношения', serviceCode: 'nsi' },
  // Виды работ
  'allow_doc_type': { name: 'Виды документов допуска', groupCode: 'Основная НСИ', subGroupCode: 'Виды работ', serviceCode: 'nsi' },
  'labour_type': { name: 'Виды работ', groupCode: 'Основная НСИ', subGroupCode: 'Виды работ', serviceCode: 'nsi' },
  'downtime_reason': { name: 'Причины простоев', groupCode: 'Основная НСИ', subGroupCode: 'Виды работ', serviceCode: 'nsi' },
  'processing_operation': { name: 'Технологические операции', groupCode: 'Основная НСИ', subGroupCode: 'Виды работ', serviceCode: 'nsi' },
  // Номенклатура
  'profile_kind': { name: 'Виды профиля', groupCode: 'Основная НСИ', subGroupCode: 'Номенклатура', serviceCode: 'nsi' },
  'unit_group': { name: 'Группы ЕИ', groupCode: 'Основная НСИ', subGroupCode: 'Номенклатура', serviceCode: 'nsi' },
  'nomenclature_group': { name: 'Группы номенклатуры', groupCode: 'Основная НСИ', subGroupCode: 'Номенклатура', serviceCode: 'nsi' },
  'unit': { name: 'Единицы измерения', groupCode: 'Основная НСИ', subGroupCode: 'Номенклатура', serviceCode: 'nsi' },
  'replacements': { name: 'Замены номенклатуры', groupCode: 'Основная НСИ', subGroupCode: 'Номенклатура', serviceCode: 'nsi' },
  'nom_category': { name: 'Категории номенклатуры', groupCode: 'Основная НСИ', subGroupCode: 'Номенклатура', serviceCode: 'nsi' },
  'material_grade': { name: 'Марки материала', groupCode: 'Основная НСИ', subGroupCode: 'Номенклатура', serviceCode: 'nsi' },
  'nomenclature': { name: 'Номенклатура', groupCode: 'Основная НСИ', subGroupCode: 'Номенклатура', serviceCode: 'nsi' },
  'material_profile': { name: 'Профили материала', groupCode: 'Основная НСИ', subGroupCode: 'Номенклатура', serviceCode: 'nsi' },
  'serial_number': { name: 'Серийные номера', groupCode: 'Основная НСИ', subGroupCode: 'Номенклатура', serviceCode: 'nsi' },
  // Оборудование
  'equipment_type': { name: 'Виды оборудования', groupCode: 'Основная НСИ', subGroupCode: 'Оборудование', serviceCode: 'nsi' },
  'equipment_group': { name: 'Группы оборудования', groupCode: 'Основная НСИ', subGroupCode: 'Оборудование', serviceCode: 'nsi' },
  'equipment': { name: 'Единицы оборудования', groupCode: 'Основная НСИ', subGroupCode: 'Оборудование', serviceCode: 'nsi' },
  // Персонал
  'rate_kind': { name: 'Виды тарифов', groupCode: 'Основная НСИ', subGroupCode: 'Персонал', serviceCode: 'nsi' },
  'flp': { name: 'МОЛ', groupCode: 'Основная НСИ', subGroupCode: 'Персонал', serviceCode: 'nsi' },
  'absence_reason': { name: 'Причины отсутствия', groupCode: 'Основная НСИ', subGroupCode: 'Персонал', serviceCode: 'nsi' },
  'profession': { name: 'Профессии', groupCode: 'Основная НСИ', subGroupCode: 'Персонал', serviceCode: 'nsi' },
  'employee': { name: 'Сотрудники', groupCode: 'Основная НСИ', subGroupCode: 'Персонал', serviceCode: 'nsi' },
  'working_conditions': { name: 'Условия труда', groupCode: 'Основная НСИ', subGroupCode: 'Персонал', serviceCode: 'nsi' },
  // Структура производства
  'schedule': { name: 'Графики работы', groupCode: 'Основная НСИ', subGroupCode: 'Структура производства', serviceCode: 'nsi' },
  'sell_units': { name: 'Складские ячейки', groupCode: 'Основная НСИ', subGroupCode: 'Структура производства', serviceCode: 'nsi' },
  'production_unit': { name: 'Структура производства', groupCode: 'Основная НСИ', subGroupCode: 'Структура производства', serviceCode: 'nsi' },
  // Другие справочники
  'scrap_reason': { name: 'Виды брака', groupCode: 'Основная НСИ', subGroupCode: 'Другие справочники', serviceCode: 'nsi' },
  'change_reason': { name: 'Причины изменений', groupCode: 'Основная НСИ', subGroupCode: 'Другие справочники', serviceCode: 'nsi' },
  'non_comp_reason': { name: 'Причины несоответствия', groupCode: 'Основная НСИ', subGroupCode: 'Другие справочники', serviceCode: 'nsi' },
  'assignment_doc': { name: 'Указания о заделе', groupCode: 'Основная НСИ', subGroupCode: 'Другие справочники', serviceCode: 'nsi' },
  'cost_code': { name: 'Шифры затрат', groupCode: 'Основная НСИ', subGroupCode: 'Другие справочники', serviceCode: 'nsi' },
  'dict_context': { name: 'Контексты', groupCode: 'Основная НСИ', subGroupCode: 'Другие справочники', serviceCode: 'nsi' },
  // 1 ур. Технологическая НСИ
  // Технологические составы
  'tech_revision': { name: 'Технологические составы', groupCode: 'Технологическая НСИ', subGroupCode: 'Технологические составы', serviceCode: 'nsi' },
  // Техпроцессы
  'work_techprocess': { name: 'Рабочие техпроцессы', groupCode: 'Технологическая НСИ', subGroupCode: 'Техпроцессы', serviceCode: 'nsi' },
  'type_techproc': { name: 'Типовые техпроцессы', groupCode: 'Технологическая НСИ', subGroupCode: 'Техпроцессы', serviceCode: 'nsi' },
  'techprocess_template': { name: 'Шаблоны техпроцесса', groupCode: 'Технологическая НСИ', subGroupCode: 'Техпроцессы', serviceCode: 'nsi' },
  // Заказы
  'order_fp_direction_1': { name: 'Заказы на ГП', groupCode: 'Технологическая НСИ', subGroupCode: 'Заказы', api: 'order_fp', urlParams: 'direction=1', serviceCode: 'nsi' },
  'order_fp_direction_2': { name: 'Внутренний ГП', groupCode: 'Технологическая НСИ', subGroupCode: 'Заказы', api: 'order_fp', urlParams: 'direction=2', serviceCode: 'nsi' },
  'order_fp_direction_3': { name: 'Кооперация', groupCode: 'Технологическая НСИ', subGroupCode: 'Заказы', api: 'order_fp', urlParams: 'direction=3', serviceCode: 'nsi' },
  'order_fpp': { name: 'Заказы-Потребности', groupCode: 'Технологическая НСИ', subGroupCode: 'Заказы', serviceCode: 'nsi' },
  'work_order': { name: 'Заказы на производство', groupCode: 'Технологическая НСИ', subGroupCode: 'Заказы', serviceCode: 'dsp' },
  // 1 ур. Логистика
  // Запасы
  'calc_gas_by_date': { name: 'Остатки на дату ТМЦ', groupCode: 'Логистика', subGroupCode: 'Запасы', api: 'calc_gas', urlParams: 'register_date={date:Дата:YYYY-MM-DD}', serviceCode: 'dsp', isOnlyRead: true },
  'calc_gas_by_unit': { name: 'Наличие ТМЦ на МХ', groupCode: 'Логистика', subGroupCode: 'Запасы', api: 'calc_gas', urlParams: 'production_unit={dict:production_unit}', serviceCode: 'dsp', isOnlyRead: true },
  'calc_wip_by_date': { name: 'Остатки на дату НЗП', groupCode: 'Логистика', subGroupCode: 'Запасы', api: 'calc_wip', urlParams: 'register_date={date:Дата:YYYY-MM-DD}', serviceCode: 'dsp', isOnlyRead: true },
  'calc_wip_by_unit': { name: 'Наличие НЗП на МХ', groupCode: 'Логистика', subGroupCode: 'Запасы', api: 'calc_wip', urlParams: 'production_unit={dict:production_unit}', serviceCode: 'dsp', isOnlyRead: true },
  // Партии
  'lot': { name: 'Партии', groupCode: 'Логистика', subGroupCode: 'Партии', serviceCode: 'dsp' },
  'lot_work_order': { name: 'Задания на работу', groupCode: 'Логистика', subGroupCode: 'Партии', serviceCode: 'dsp' },
  // Логистика
  'base_doc_0': { name: 'Запуск в производстве', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=0', serviceCode: 'dsp' },
  'base_doc_1': { name: 'Передача в производстве', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=1', serviceCode: 'dsp' },
  'base_doc_2': { name: 'Приемка и регистрация брака', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=2', serviceCode: 'dsp' },
  'base_doc_3': { name: 'Получение в производстве', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=3', serviceCode: 'dsp' },
  'base_doc_4': { name: 'Разделение заказа', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=4', serviceCode: 'dsp' },
  'base_doc_5': { name: 'Разделение партии', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=5', serviceCode: 'dsp' },
  'base_doc_6': { name: 'Решение о браке', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=6', serviceCode: 'dsp' },
  'base_doc_7': { name: 'Списание в производстве', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=7', serviceCode: 'dsp' },
  'base_doc_8': { name: 'Разборка', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=8', serviceCode: 'dsp' },
  'base_doc_9': { name: 'Отпуск в производство', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=9', serviceCode: 'dsp' },
  'base_doc_10': { name: 'Корректировка остатков', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=10', serviceCode: 'dsp' },
  'base_doc_11': { name: 'Решение о заделе', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=11', serviceCode: 'dsp' },
  'base_doc_12': { name: 'Остановка работ', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=12', serviceCode: 'dsp' },
  'base_doc_13': { name: 'Отгрузка заказчику', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=13', serviceCode: 'dsp' },
  'base_doc_14': { name: 'Отмена заказа', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=14', serviceCode: 'dsp' },
  'base_doc_15': { name: 'Инвентаризационная ведомость', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=15', serviceCode: 'dsp' },
  'base_doc_16': { name: 'Изменение места выполнения', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=16', serviceCode: 'dsp' },
  'base_doc_17': { name: 'Закрытие заказа', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=17', serviceCode: 'dsp' },
  'base_doc_18': { name: 'Перемещение остатков', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=18', serviceCode: 'dsp' },
  'base_doc_19': { name: 'Приход от поставщика', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=19', serviceCode: 'dsp' },
  'base_doc_20': { name: 'Ведомость комплектации', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=20', serviceCode: 'dsp' },
  'base_doc_21': { name: 'Возврат не принятых', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=21', serviceCode: 'dsp' },
  'base_doc_22': { name: 'Выпуск задним числом', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=22', serviceCode: 'dsp' },
  'base_doc_23': { name: 'Изменение заказа', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=23', serviceCode: 'dsp' },
  'base_doc_24': { name: 'Резервирование прихода', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=24', serviceCode: 'dsp' },
  'base_doc_25': { name: 'Отмена комплектации и резервов', groupCode: 'Логистика', subGroupCode: 'Логистика', api: 'base_doc', urlParams: 'operation_type=25', serviceCode: 'dsp' },
  // Настройки
  'doc_direction': { name: 'Направления ДУ', groupCode: 'Логистика', subGroupCode: 'Настройки', serviceCode: 'dsp' },
  'characteristic': { name: 'Характеристики', groupCode: 'Логистика', subGroupCode: 'Настройки', serviceCode: 'dsp' },
  'variable': { name: 'Переменные', groupCode: 'Логистика', subGroupCode: 'Настройки', serviceCode: 'dsp' },
  // 1 ур. Регистры
  'register_gas': { name: 'ТМЦ', groupCode: 'Регистры', serviceCode: 'dsp', isOnlyRead: true },
  'register_wip': { name: 'НЗП', groupCode: 'Регистры', serviceCode: 'dsp', isOnlyRead: true },
  'calc_gas': { name: 'ТМЦ итог', groupCode: 'Регистры', serviceCode: 'dsp', isOnlyRead: true },
  'calc_wip': { name: 'НЗП итог', groupCode: 'Регистры', serviceCode: 'dsp', isOnlyRead: true },
  // 1 ур. Администратор
  'users': { name: 'Пользователи', groupCode: 'Администратор', serviceCode: 'adm' },
  'roles': { name: 'Роли пользователей', groupCode: 'Администратор', serviceCode: 'adm' },
};

export type FormCode = keyof typeof Forms;

export interface IForm extends Form {
  code: FormCode;
}

export const FormsList: IForm[] = (
  Object.entries(Forms) as [FormCode, (typeof Forms)[FormCode]][]
).map(
  ([
    code,
    { api, urlParams, name, groupCode, subGroupCode, serviceCode, isOnlyRead },
  ]) => ({
    code,
    api,
    urlParams,
    name,
    groupCode,
    subGroupCode,
    serviceCode,
    isOnlyRead,
  })
);

// export const FormsList: IForm[] = ;

export interface ListItemT {
  name: string;
  path: string;
}

export interface MenuSubgroupT {
  code: string;
  name: string;
  path?: string;
  lists: ListItemT[];
}

export interface MenuGroupT {
  code: string;
  name: string;
  path: string;
  iconName: IconName;
  lists?: ListItemT[];
  subgroups?: MenuSubgroupT[];
}

function transformDataToMenu(
  groups: IFormGroup[],
  subgroups: IFormSubGroup[],
  forms: IForm[]
): MenuGroupT[] {
  return groups.map((group) => {
    const groupSubgroups = subgroups
      .filter((subgroup) =>
        forms.some(
          (form) =>
            form.groupCode === group.code && form.subGroupCode === subgroup.code
        )
      )
      .map((subgroup) => {
        const subgroupForms = forms
          .filter(
            (form) =>
              form.groupCode === group.code &&
              form.subGroupCode === subgroup.code
          )
          .map((form) => ({
            path: `/forms/${group.path}/${subgroup.path}/${form.code}`,
            name: form.name,
          }));

        return {
          code: subgroup.code,
          name: subgroup.code,
          path: `/forms/${group.path}/${subgroup.code}`,
          lists: subgroupForms,
        };
      });

    const listForms = forms
      .filter((form) => form.groupCode === group.code)
      .map((form) => ({
        path: `/forms/${group.path}/${form.code}`,
        name: form.name,
      }));

    const children = groupSubgroups.length
      ? { subgroups: groupSubgroups }
      : { lists: listForms };

    return {
      code: group.code,
      name: group.code,
      path: `/forms/${group.path}`,
      iconName: group.iconName,
      ...children,
    };
  });
}

export const getListMenuArray = (filter: string = '') => {
  return transformDataToMenu(
    GroupsList,
    SubGroupsList,
    FormsList.filter(
      (form) =>
        filter === '' || form.name.toLowerCase().includes(filter.toLowerCase())
    )
  );
};
