import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BreadCrumbs } from '../components/common/BreadCrumbs/BreadCrumbs';
import { CardFormController } from '../components/forms/CardFormController/CardFormController';
import {
  FormsList,
  GroupsList,
  SubGroupsList,
} from '../models/list-forms-model';
import { isInt } from '../utils/functions';

export const CardFormPage = () => {
  const navigate = useNavigate();

  const { formCode, id } = useParams();

  const form = FormsList.find((form) => form.code === formCode);

  if (!form) return null;

  const recordId = isInt(id) ? parseInt(id || '') : undefined;

  const pathToListForm = `/forms/${form.groupCode}/${form.subGroupCode}/${form.code}`;

  const groupName = GroupsList.find((g) => g.code === form.groupCode)?.code;
  const subGroupName = SubGroupsList.find(
    (g) => g.code === form.subGroupCode
  )?.code;

  if (!(id === 'new' || isInt(id))) {
    setTimeout(() => {
      navigate(pathToListForm);
    }, 0);
    return null;
  }

  return (
    <>
      <div style={{ padding: '0 24px' }}>
        <BreadCrumbs
          backLink={pathToListForm}
          breadCrumbs={[
            { label: 'Главная', toLink: '/' },
            groupName ? { label: groupName } : undefined,
            subGroupName ? { label: subGroupName } : undefined,
            { label: form.name, toLink: pathToListForm },
          ]}
        />
      </div>
      <CardFormController form={form} id={recordId} />
    </>
  );
};
