import { clsx } from 'clsx';
import React, { useContext, useState } from 'react';
import { produce } from 'immer';
import { IForm } from '../../../models/list-forms-model';
import { MetadataContext } from '../../../propviders/MetadataPropvider';
import { ListFormData, SchemaField } from '../../../types/common/form';
import { Button } from '../../uikit/Button/Button';
import { Icon } from '../../uikit/Icon/Icon';
import ModalDelete from '../../uikit/Modals/ModalDelete';
import { FormListView } from '../CardForms/CardFormSimple';
import { useFormSchemaAndData } from '../hooks';
import { ListFormTable } from '../ListFormTable/ListFormTable';
import { apiDeleteDictRecord } from '../../../servises/dict';
import { ButtonIcon } from '../../uikit/ButtonIcon/ButtonIcon';
import { ListFormListViewController } from './ListFormListViewController';

import styles from './ListFormListViewTabs.module.css';

const listFormHeaderHeight = 36;
const listFormHeaderBottomMargin = 10;

type Props = {
  listViews: FormListView[];
};

export const ListFormListViewTabs = ({ listViews }: Props) => {
  const [tabIndex, setTabIndex] = useState(0);

  const listViewName = listViews[tabIndex]?.label;

  const listView = listViews[tabIndex];

  return (
    <div
      style={{
        border: '1px solid rgba(239, 242, 247, 1)',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: listFormHeaderHeight,
          background: 'var(--color-snow-dark)',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            overflowY: 'hidden',
            height: listFormHeaderHeight,
          }}
        >
          {listViews.map((listView, index) => (
            <span
              className={clsx(styles.TabItem, {
                [styles.Active]: index === tabIndex,
              })}
              style={{
                height: listFormHeaderHeight,
              }}
              onClick={() => setTabIndex(index)}
            >
              {listView.label}
            </span>
          ))}
        </div>
      </div>
      <div
        style={{
          width: '100%',
          height: `calc(100% - ${listFormHeaderHeight}px)`,
          overflow: 'auto',
          padding: 16,
        }}
      >
        {listView && <ListFormListViewController listView={listView} />}
      </div>

      {/*{ isOpenDeleteModal && handleDeleteRecords && <ModalDelete handleDelete={handleDeleteRecords} handleCancel={() => setIsOpenDeleteModal(false)} /> }*/}
    </div>
  );
};
