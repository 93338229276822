import { produce } from 'immer';
import React, { useContext, useEffect, useState } from 'react';
import { FormsList } from '../../../models/list-forms-model';
import {
  MetaColumnFieldTypes,
  MetadataContext,
} from '../../../propviders/MetadataPropvider';
import {
  apiGetDict,
  apiGetListViewMeta,
} from '../../../servises/dict';
import {
  FieldType,
  FieldValueObject,
  FormDataItem,
} from '../../../types/common/form';
import { Button } from '../../uikit/Button/Button';
import Modal from '../../uikit/Modals/Modal';
import styles from '../../uikit/Modals/ModalDelete.module.css';
import { CardFormSimple, FormField } from '../CardForms/CardFormSimple';
import { ServiceFields } from '../helper';

interface Props {
  listViewCode: string;
  record?: FormDataItem;
  handleSubmit: (record: FormDataItem) => void;
  handleCancel: () => void;
}

export const ModalListViewEditRecordController = ({
  listViewCode,
  record,
  handleSubmit,
  handleCancel,
}: Props) => {
  const { isLoadingMetadata, metaFields, metaEnums, metaListViews } =
    useContext(MetadataContext);

  // буферный стейт справочников, для того, чтобы не хранить их в cardFormFields
  const [dictionaries, setDictionaries] = useState<
    Record<string, FormDataItem[]>
  >({});
  const saveDictionary = (code: string, records: FormDataItem[]) => {
    setDictionaries((oldFields) =>
      produce(oldFields, (draft) => {
        draft[code] = records;
      })
    );
  };

  const [cardFormFields, setCardFormFields] = useState<FormField[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isNewRecord = !record;

  const setFieldValue = (fieldCode: string, value: string | null) => {
    setCardFormFields((oldFields) =>
      produce(oldFields, (draft) => {
        const index = oldFields.findIndex((field) => field.code === fieldCode);
        if (index >= 0) {
          draft[index].value = value;
        }
      })
    );
  };

  useEffect(() => {
    const dict = metaListViews.find((view) => view.objectId === listViewCode);

    if (dict) {
      setIsLoading(true);

      apiGetListViewMeta(dict?.service, dict?.dict).then((response) => {
        const fields = response.fields;

        const schema: { code: string; type: FieldType }[] = [];

        Object.keys(fields).forEach((key) => {
          if (
            key in metaFields &&
            MetaColumnFieldTypes.includes(metaFields[key]?.type) &&
            !ServiceFields.includes(key)
          ) {
            schema.push({
              code: key,
              type: metaFields[key]?.type || 'String',
            });
          }
        });

        const getCardFields = async () => {
          return Promise.all(
            schema.map(async ({ code, type }) => {
              let options = undefined;

              if (type === 'Enum') {
                options = metaEnums[metaFields[code]?.dict]?.valuesArray.map(
                  (item) => ({
                    name: item.localName,
                    value: item.objectId,
                  })
                );
              }
              if (type === 'Dictionary') {
                const dictCode = metaFields[code]?.dict;
                const form = FormsList.find((form) => form.code === dictCode);
                const dictData = form
                  ? (
                      await apiGetDict(
                        form.serviceCode,
                        form?.api || dictCode,
                        form.urlParams
                      )
                    ).data
                  : [];
                saveDictionary(form?.api || dictCode, dictData);

                options = dictData.map((item) => ({
                  name: String(item.fullName || ''),
                  value: String(item.id || ''),
                }));
              }

              return {
                code,
                label: metaFields[code]?.localName || code,
                type,
                oldValue: type === 'String' ? '' : null,
                value: type === 'String' ? '' : null,
                options,
              };
            })
          );
        };

        getCardFields()
          .then((formFieldsWithoutValue) => {
            if (record) {
              const formFields = formFieldsWithoutValue.map((field) => ({
                ...field,
                value: field.type === 'Dictionary' ? String((record[field.code] as FieldValueObject)?.id) : record[field.code] === null ? null : String(record[field.code]),
              }));
              setCardFormFields(formFields);
            } else {
              setCardFormFields(formFieldsWithoutValue);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
  }, [listViewCode, metaEnums, metaFields, metaListViews, record]);

  const handleApply = () => {
    const recordToSave: FormDataItem = cardFormFields.reduce(
      (acc, field) => {
        return {
          ...acc,
          [field.code]:
            metaFields[field.code].type === 'Dictionary'
              ? dictionaries[field.code].find(
                  (row) => row.id === Number(field.value)
                )
              : metaFields[field.code].type === 'Number'
                ? Number(field.value)
                : field.value,
        };
      },
      { _key: record?._key || -1, _selected: Boolean(record?._selected) }
    );
    handleSubmit(recordToSave);
  };

  return (
    <Modal
      open
      title={isNewRecord ? 'Добавить запись' : 'Изменить запись'}
      handleClose={handleCancel}
    >
      <div className={styles.Content}>
        {isLoadingMetadata || isLoading ? (
          <div>Загрузка...</div>
        ) : (
          <>
            <CardFormSimple
              fields={cardFormFields}
              setFieldValue={setFieldValue}
            />
            <div className={styles.Control}>
              <Button onClick={handleApply}>Применить</Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
