import React, { useState } from 'react';
import { produce } from 'immer';
import { Link, useNavigate } from 'react-router-dom';
import { Groups, IForm, SubGroups } from '../../../models/list-forms-model';
import { Button } from '../../uikit/Button/Button';
import { Icon } from '../../uikit/Icon/Icon';
import ModalDelete from '../../uikit/Modals/ModalDelete';
import { useFormSchemaAndData } from '../hooks';
import { ListFormTable } from '../ListFormTable/ListFormTable';
import { apiDeleteDictRecord } from '../../../servises/dict';
import { ButtonIcon } from '../../uikit/ButtonIcon/ButtonIcon';

const listFormHeaderHeight = 56;
const listFormHeaderBottomMargin = 10;

type ListFormProps = {
  form: IForm;
};

export const ListFormController = ({ form }: ListFormProps) => {
  const navigate = useNavigate();

  const { name: formName } = form;

  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const { formData, setFormData, isLoading, fetchDict } = useFormSchemaAndData({
    form,
  });

  if (isLoading) return <>Loading...</>;

  const selectedRows = formData.rows.filter(({ _selected }) => _selected);

  const selectRow = (key: number, value: boolean) => {
    setFormData((_form) =>
      produce(_form, (draft) => {
        const rowIndex = draft.rows.findIndex(({ _key }) => _key === key);
        draft.rows[rowIndex]._selected = value;
      })
    );
  };

  // const setFieldProp: SetFieldProp = (code, propName, value) => {
  //   setForm((_form) =>
  //     produce(_form, (draft) => {
  //       const fieldIndex = draft.fields.findIndex(
  //         (field) => field.code === code
  //       );
  //       draft.fields[fieldIndex][propName] = value;
  //     })
  //   );
  // };

  const setSortField = (code: string) => {
    setFormData((_form) =>
      produce(_form, (draft) => {
        const fieldIndex = draft.fields.findIndex(
          (field) => field.code === code
        );
        const currentSort = draft.fields[fieldIndex].sort;
        // сбрасываем сортировки
        for (const field of draft.fields) {
          field.sort = null;
        }
        switch (currentSort) {
          case 'ASC': {
            draft.fields[fieldIndex].sort = 'DESC';
            return;
          }
          case 'DESC': {
            draft.fields[fieldIndex].sort = null;
            return;
          }
          default: {
            draft.fields[fieldIndex].sort = 'ASC';
            return;
          }
        }
      })
    );
  };

  const handleEditRecord = (id: string) => {
    navigate(
      // TODO есть повторение в коде в другом месте где формируется эта строка, нужно связать эти участи кода
      `/forms/${Groups[form.groupCode].path}/${ form.subGroupCode ? SubGroups[form.subGroupCode].path + '/' : ''}${form.code}/${id}`
    );
  };

  const handleDeleteRecords =
    selectedRows.length === 0
      ? undefined
      : () => {
          setIsDeletingLoading(true);
          const ids = selectedRows
            .map((row) => row.id as number)
            .filter(Boolean);
          const promises = ids.map((id) => {
            return apiDeleteDictRecord(
              form.serviceCode,
              form.api || form.code,
              String(id)
            );
          });
          Promise.all(promises)
            .then(() => {
              fetchDict();
            })
            .catch((err) => {
              console.error('Ошибка при удалении записей', err);
            })
            .finally(() => {
              setIsDeletingLoading(false);
              setIsOpenDeleteModal(false);
            });
        };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: listFormHeaderHeight,
          marginBottom: listFormHeaderBottomMargin,
        }}
      >
        <h2
          style={{
            fontSize: 18,
            lineHeight: '26px',
          }}
        >
          {formName}
        </h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 8,
          }}
        >
          {!form.isOnlyRead && (<>
            <Link to="./new">
              <Button variant="additional">Создать</Button>
            </Link>
            <Button
              variant="text"
              iconEnd={<Icon name="Edit" />}
              overrideFillIcon
              onClick={
                selectedRows.length !== 1
                  ? undefined
                  : () => {
                    handleEditRecord(String(selectedRows?.[0]?.id));
                  }
              }
              disabled={selectedRows.length !== 1}
            />
            <ButtonIcon
              variant="text"
              iconName="Delete"
              onClick={() => setIsOpenDeleteModal(true)}
              disabled={selectedRows.length === 0 || isDeletingLoading}
            />
            <div
              style={{
                height: 32,
                width: 1,
                backgroundColor: 'rgba(229, 233, 242, 1)',
              }}
            />
          </>)}

          <Button
            variant="text"
            iconEnd={<Icon name="Filter" />}
            overrideFillIcon
          />
          <Button
            variant="text"
            iconEnd={<Icon name="Settings" />}
            overrideFillIcon
          />
        </div>
      </div>
      <div
        style={{
          width: '100%',
          height: `calc(100% - ${listFormHeaderHeight + listFormHeaderBottomMargin}px)`,
          overflow: 'auto',
        }}
      >
        <ListFormTable
          listFormData={formData}
          selectRow={selectRow}
          // setFieldProp={setFieldProp}
          setSortField={setSortField}
          handleDoubleRecordClick={handleEditRecord}
        />
      </div>

      {/*<div>Выделенные строки:</div>*/}
      {/*{formData.rows*/}
      {/*  .filter(({ _selected }) => _selected)*/}
      {/*  .map((row) => (*/}
      {/*    <div key={row._key}>{JSON.stringify(row)},</div>*/}
      {/*  ))}*/}

      {/*<div>Поля:</div>*/}
      {/*{formData.fields.map((field) => (*/}
      {/*  <div key={field.code}>{JSON.stringify(field)},</div>*/}
      {/*))}*/}
      {isOpenDeleteModal && handleDeleteRecords && (
        <ModalDelete
          handleDelete={handleDeleteRecords}
          handleCancel={() => setIsOpenDeleteModal(false)}
        />
      )}
    </>
  );
};
