export const VALID_TYPES = ['string', 'number', 'date', 'dict'] as const;
export type ParameterFuncType = typeof VALID_TYPES[number];

export type UrlParameter = {
  type: ParameterFuncType;
  argument: string[];
  value: string;
};

export type ParseUrlTemplateResult = {
  template: string;
  parameters: UrlParameter[];
};

// Type guard для проверки допустимости типа
const isValidParameterType = (value: string): value is ParameterFuncType => {
  return VALID_TYPES.includes(value as ParameterFuncType);
};

export const parseUrlTemplate = (template: string): ParseUrlTemplateResult => {
  const parameterRegex = /\{(?:[^{}\\]|\\.)*?\}/g;
  const result: UrlParameter[] = [];
  let match;

  while ((match = parameterRegex.exec(template)) !== null) {
    const fullMatch = match[0];
    let content = fullMatch.slice(1, -1)
      .replace(/\\:/g, 'TEMP_COLON')
      .replace(/\\(.)/g, '$1');

    const parts = content.split(':').map(p => p.replace(/TEMP_COLON/g, ':'));

    const [firstPart, ...restParts] = parts;

    // Используем type guard для безопасного определения типа
    const type = isValidParameterType(firstPart) ? firstPart : 'string';
    const args = type === firstPart ? restParts : parts;

    result.push({
      type,
      argument: args,
      value: '',
    });
  }

  return { template, parameters: result };
};

export const replaceUrlParameters = (parseResult: ParseUrlTemplateResult): string => {
  const { template, parameters } = parseResult;
  const parameterRegex = /\{(?:[^{}\\]|\\.)*?\}/g;
  const matches = Array.from(template.matchAll(parameterRegex));
  let result = '';
  let lastIndex = 0;

  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    const param = parameters[i];
    // Добавляем часть шаблона до текущего параметра
    result += template.substring(lastIndex, match.index);
    // Добавляем значение параметра
    if (!param || !match) break;
    // Добавляем значение параметра
    result += param.value;
    // Обновляем lastIndex до конца текущего параметра
    lastIndex = (match.index || 0) + match[0].length;
  }
  // Добавляем оставшуюся часть шаблона после последнего параметра
  result += template.substring(lastIndex);
  return result;
}
