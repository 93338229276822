import React, { useEffect, useMemo, useState } from 'react';
import { IForm } from '../../../models/list-forms-model';
import {
  parseUrlTemplate,
  ParseUrlTemplateResult,
  replaceUrlParameters,
} from '../../../utils/urlTemplate';
import { ListFormController } from '../ListFormController/ListFormController';
import { Button } from '../../uikit/Button/Button';
import { renderUserFilterInput } from './UserFiltersInputs';

import styles from './UserFiltersFormController.module.css';

type EditedValues = {
  code: string;
  values: string[];
};

const fillValues = (
  parsedUrlTemplateNotFilled: ParseUrlTemplateResult,
  filterValues: string[]
) => {
  return {
    ...parsedUrlTemplateNotFilled,
    parameters: parsedUrlTemplateNotFilled.parameters.map((par, index) => ({
      ...par,
      value: filterValues[index] || '',
    })),
  };
};

type Props = {
  form: IForm;
};

export const UserFiltersFormController = ({ form }: Props) => {
  const [userFilterValues, setUserFilterValues] = useState<EditedValues>({
    code: '',
    values: [],
  });
  const [appliedValues, setAppliedValues] = useState<EditedValues>({
    code: '',
    values: [],
  });

  const parsedUrlTemplateNotFilled = useMemo(
    () => (form.urlParams ? parseUrlTemplate(form.urlParams) : null),
    [form.urlParams]
  );

  useEffect(() => {
    if (parsedUrlTemplateNotFilled) {
      setUserFilterValues({
        code: form.code,
        values: parsedUrlTemplateNotFilled.parameters.map((_) => ''),
      });
      setAppliedValues({
        code: form.code,
        values: parsedUrlTemplateNotFilled.parameters.map((_) => ''),
      });
    }
  }, [form.code, parsedUrlTemplateNotFilled]);

  if (
    !parsedUrlTemplateNotFilled ||
    parsedUrlTemplateNotFilled.parameters.length === 0
  ) {
    return <ListFormController form={form} />;
  }

  const parsedUrlTemplateForEdit = fillValues(
    parsedUrlTemplateNotFilled,
    userFilterValues.code === form.code ? userFilterValues.values : []
  );
  const parsedUrlTemplate = fillValues(
    parsedUrlTemplateNotFilled,
    appliedValues.code === form.code ? appliedValues.values : []
  );

  const newUrlParams = replaceUrlParameters(parsedUrlTemplate);

  const formWithParams = { ...form, urlParams: newUrlParams };

  const isParametersForEditFilled = parsedUrlTemplateForEdit.parameters
    .map(({ value }) => value)
    .every(Boolean);
  const isParametersFilled = parsedUrlTemplate.parameters
    .map(({ value }) => value)
    .every(Boolean);

  const handleChange = (index: number, newValue: string) => {
    setUserFilterValues((prev) => {
      const newValues = [...prev.values];
      newValues[index] = newValue;
      return { code: prev.code, values: newValues };
    });
  };

  return (
    <div>
      <div className={styles.Wrap}>
        {parsedUrlTemplateForEdit.parameters.map((parameter, index) => (
          <div key={index} style={{ display: 'flex', gap: 16 }}>
            {renderUserFilterInput(parameter, (newValue) =>
              handleChange(index, newValue)
            )}
          </div>
        ))}
        <Button
          onClick={() => setAppliedValues(userFilterValues)}
          disabled={!isParametersForEditFilled}
        >
          Применить
        </Button>
      </div>
      {isParametersFilled && <ListFormController form={formWithParams} />}
    </div>
  );
};
