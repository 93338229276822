import React from 'react';
import { useParams } from 'react-router-dom';
import { BreadCrumbs } from '../components/common/BreadCrumbs/BreadCrumbs';
import { ListFormController } from '../components/forms/ListFormController/ListFormController';
import {
  UserFiltersFormController
} from '../components/forms/UserFormParametersController/UserFiltersFormController';
import { FormsList, Groups, GroupsList, SubGroups, SubGroupsList } from '../models/list-forms-model';

export const ListFormPage = () => {

  const { formCode } = useParams();

  const form = FormsList.find((form) => form.code === formCode);

  if (!form) return null;

  // TODO есть повторение в коде в другом месте где формируется эта строка, нужно связать эти участи кода
  const pathToListForm = `/forms/${Groups[form.groupCode].path}/${ form.subGroupCode ? SubGroups[form.subGroupCode].path + '/' : ''}${form.code}`;

  const groupName = GroupsList.find((g) => g.code === form.groupCode)?.code;
  const subGroupName = SubGroupsList.find(
    (g) => g.code === form.subGroupCode
  )?.code;

  return (
    <>
      <div style={{padding: '0 24px'}}>
        <BreadCrumbs
          breadCrumbs={[
            {label: 'Главная', toLink: '/'},
            groupName ? {label: groupName} : undefined,
            subGroupName ? {label: subGroupName} : undefined,
            {label: form.name, toLink: pathToListForm},
          ]}
        />
      </div>
      <div style={{ padding: '0 20px 20px 20px', height: `calc(100% - var(--size-breadcrumbs-height))`}}>
        <UserFiltersFormController form={form} />
      </div>
    </>
)
  ;
}
